body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#payment-name-input::placeholder {
    color: #aab7c4;
}

#payment-name-input:-ms-input-placeholder {
    color: #aab7c4;
}

#payment-name-input::-ms-input-placeholder {
    color: #aab7c4;
}

@media print {
    footer {
        display: none !important;
    }

    .print-button {
        display: none !important;
    }
}
