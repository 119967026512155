$primary: #AC8F00;

$theme-colors: (
        "primary": $primary,
        "danger": #ff4136
);

@import '~bootstrap/scss/bootstrap.scss';

%custom-radio {
  width: 19px;
  height: 19px;
  border-radius: 18px;
  top: -2px;
  left: -2px;
  position: relative;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:after {
  @extend %custom-radio;
  background-color: #d1d3d1;
}

input[type='radio']:checked:after {
  @extend %custom-radio;
  background-color: $primary;
}
